import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { createAction, createReducer } from 'redux-act';
import { OAuthResponse } from '../../models/OAuthResponse';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { AnalyticsInit } from '../../services/Analytics/AnalyticsInit';
import { authService } from '../../services/AuthService';
import { EagleLoginService } from '../../services/EagleLoginService';
import { hsnAuthService } from '../../services/HsnAuthService';
import { usaTodayAuthService } from '../../services/UsaTodayAuthService';
import { userService } from '../../services/UserService';
import { AppState } from '../types';
import { setLoginOpen } from './modal';
import { setUserAuthStatus } from './userAuthStatus';
import { setUserTopScores } from './userTopScores';

export const setUser = createAction<User | EagleUser>('set user');
export const updateUser = createAction<User | UserProfileDTO>('update user');

const reducer = createReducer<User | EagleUser>({}, null);

reducer.on(setUser, (_state, payload: User | EagleUser = null) => payload);

reducer.on(updateUser, (_state, payload: User = null) => ({ ..._state, ...payload }));

export default reducer;

/* Thunks */
export function restoreUserIfAuthorizedThunk() {
  return function (dispatch, getState: () => AppState) {
    const tokenFromUrl = authService.getTokenFromUrl();
    const { config } = getState() as AppState;

    if (tokenFromUrl) {
      authService.setToken(tokenFromUrl);
    }

    let token = authService.getToken();

    if (!token) {
      const cookieToken = authService.getTokenFromCookie();

      if (cookieToken) {
        token = cookieToken;
        authService.setToken(token);
      }
    }

    if (!token && config.sso?.name === 'usatoday') {
      usaTodayAuthService
        .getUserInformation()
        .then((user: User) => {
          if (user) {
            dispatch(loginUser(user));
          }
        })
        .catch(() => {
          dispatch(logoutUser());
        });
      return;
    }

    if (!token && config.sso?.name === 'hsn') {
      return hsnAuthService.getUserInformation().then(r => r);
    }

    // TODO login with 2 systems (this code needs to authorize with old login after page refresh)

    if (token) {
      authService
        .fetchUser()
        .then((user) => {
          dispatch(loginUser(user));
        })
        .catch(() => {
          dispatch(logoutUser());
        });
    } else {
      dispatch(logoutUser());
    }
  };
}

// Just store partial user in closure. Used between two thunks
let unregisteredUserOauthData: OAuthResponse = null;

export function updateUserWithGDPRThunk(
  {
    gdprUserData,
    gdprEmailMarketing,
  },
  loginCaller: any,
) {
  return function (dispatch, getState: () => AppState) {
    const user = getState().user as User;
    const isRegistered = !!user;

    if (!isRegistered) {
      userService
        .createFromOAuthResponse(
          unregisteredUserOauthData,
          {
            gdprUserData,
            gdprEmailMarketing,
          },
        )
        .then((userFromServer: any) => {
          unregisteredUserOauthData = null;

          dispatch(loginUser(userFromServer));
          AnalyticsGeneral.registration(loginCaller, userFromServer.userAuthSystem);
          dispatch(setLoginOpen(false));
        });
    } else {
      const userToUpdate = {
        ...user,
        gdprUserData,
        gdprEmailMarketing,
      };

      userService.update(userToUpdate).then((updatedUser) => {
        dispatch(updateUser(updatedUser));
        dispatch(setLoginOpen(false));
      });
    }
  };
}

export function loginUser(user: EagleUser | User) {
  return function (dispatch) {
    dispatch(setUser(user));
    dispatch(setUserAuthStatus(UserAuthStatus.USER_AUTHORIZED));
    AnalyticsInit.setGlobalCustomDimensions({ dimension15: '1' }, { loggedIn: '1' });
  };
}

export function logoutUser() {
  return function (dispatch, getState: () => AppState) {
    dispatch(setUserAuthStatus(UserAuthStatus.USER_NOT_AUTHORIZED));
    dispatch(setUser(null));

    dispatch(setUserTopScores(null));

    AnalyticsInit.setGlobalCustomDimensions({ dimension15: '0' }, { loggedIn: '0' });
    authService.logout();
    EagleLoginService.logout();
    authService.userLogoutOpenweb();

    // Custom SSO logout
    if (getState().config.sso && getState().config.sso.name === 'usatoday') {
      usaTodayAuthService.logout();
    }

    if (getState().config.sso && getState().config.sso.name === 'hsn') {
      hsnAuthService.logout();
    }
  };
}
