import { SupportedLangs } from '../../i18n';
import { isServer } from '../../utils';

export const generateSeoHreflangLinksList = (
    absoluteBaseHref,
    arenaMainLang = 'en',
    subpathArr: string[] = []) => {
    const dict = myDict();
    const checkDict = (locale, key) => {
        const mappedLocale = locale ? 'en' : locale;

        return (
            dict.hasOwnProperty(key) && dict[key].hasOwnProperty(mappedLocale) // simplest case
                ? dict[key][mappedLocale]
                : subpathArr[0] === 'ROUTES.CATEGORY' && subpathArr.length >= 2 // for categories
                ? getCategoryName(subpathArr[1], mappedLocale)
                : key // for other cases (e.g. game name)
        );
    };
    const getSubpath = locale => '/' + subpathArr.map(pathPart => checkDict(locale, pathPart)).join('/');
    const origin = isServer ? absoluteBaseHref : window.location.origin;
    const pathname = isServer ? null : window.location.pathname;
    const currentPageHasLocale = pathname
        ? SupportedLangs
            .filter(
                locale => Boolean(pathname.match(new RegExp(`^\/(${locale}){1}`, 'gi')))
            )
            .reverse() // for 'en' and 'en-[sublocale]'
            [0]
        : null;
    const currentPageLocale = Boolean(currentPageHasLocale) ? currentPageHasLocale : '';
    const locales = [...SupportedLangs];

    return (locales
        .filter(locale => {
            if (currentPageHasLocale) {
                return (locale !== currentPageLocale);
            } else {
                return (locale !== arenaMainLang);
            }
        })
        .map(locale => ({
            rel: 'alternate',
            hreflang: locale,
            href: locale === arenaMainLang
                ? `${origin}${getSubpath(locale)}`
                : `${origin}/${locale}${getSubpath(locale)}`.replace(/\/$/, ''),
        }))
            .concat([
                {
                    rel: 'alternate',
                    hreflang: 'x-default',
                    href: `${origin}${getSubpath(arenaMainLang)}`.replace(/\/$/, ''),
                },
            ])
        .filter(el => Boolean(el))
    );

    function getCategoryName(categoryFromUrl, locale) {
        const categoryDict = myDict().CATEGORIES;
        const hasCategoryName = (category, categoryName = categoryFromUrl) => Object
            .values(categoryDict[category])
            .includes(categoryName);
        let categoryName = 'word';

        switch (true) {
            case (hasCategoryName('word')):
                categoryName = 'word';
                break;
            case (hasCategoryName('daily')):
                categoryName = 'daily';
                break;
            case (hasCategoryName('card')):
                categoryName = 'card';
                break;
            case (hasCategoryName('puzzles')):
                categoryName = 'puzzles';
                break;
            case (hasCategoryName('casino')):
                categoryName = 'casino';
                break;
            case (hasCategoryName('arcade')):
                categoryName = 'arcade';
                break;
            default:
                break;
        }

        return categoryDict[categoryName][locale];
    }
};

/* ToDO: Think how to replace hardcoded dict with actual data from lang jsons without fetching the whole batch */
// Yet did it like in arenax-api-admin done for sitemap.xml generator
export function myDict() {
    return ({
        'ROUTES.ALL_GAMES': {
            en: 'all-games',
            de: 'alle-spiele',
            fr: 'tous-les-jeux',
            es: 'todos-los-juegos',
            it: 'tutti-i-giochi',
        },
        'ROUTES.CATEGORY': {
            en: 'category',
            de: 'kategorie',
            fr: 'catégorie',
            es: 'categoría',
            it: 'categoria',
        },
        'ROUTES.GAMES': {
            en: 'games',
            de: 'spiele',
            fr: 'jeux',
            es: 'juegos',
            it: 'giochi',
        },
        'ROUTES.HELP': {
            en: 'help',
            de: 'hilfe',
            fr: 'assistance',
            es: 'ayuda',
            it: 'aiutare',
        },
        'ROUTES.PROFILE': {
            en: 'profile',
            de: 'profil',
            fr: 'profil',
            es: 'perfil',
            it: 'profilo',
        },
        'CATEGORIES': {
            word: {
                en: 'word',
                de: 'wort',
                fr: 'mot',
                es: 'palabras',
                it: 'parole',
            },
            daily: {
                en: 'daily',
                de: 'täglich',
                fr: 'quotidien',
                es: 'diario',
                it: 'giornaliero',
            },
            card: {
                en: 'card',
                de: 'karte',
                fr: 'carte',
                es: 'cartas',
                it: 'carte',
            },
            puzzles: {
                en: 'puzzles',
                de: 'puzzlespiel',
                fr: 'casse-tête',
                es: 'rompecabezas',
                it: 'rompicapo',
            },
            casino: {
                en: 'casino',
                de: 'casino',
                fr: 'casino',
                es: 'casino',
                it: 'casino',
            },
            arcade: {
                en: 'arcade',
                de: 'arcade',
                fr: 'arcade',
                es: 'arcade',
                it: 'arcade',
            },

        },
    });
}
