import { endsWith } from '../../utils';
import { environment } from '../config/environment';

export type GameFeedContent = {
    feedMetadata: {
        alias: string;
        name: string;
        description: string;
        rules: string;
        categories: string[];
    };
    [gameProp: string]: any;
};

export class Game {
    static getWebPImage(imagePath: string): string {
        if (imagePath && endsWith(imagePath, '.jpg')) {
            return imagePath.replace('.jpg', '.webp');
        } else {
            return '';
        }
    }

    public static ParseNew({..._game }: GameFeedContent): Game {
        const gameMeta = _game.meta;
        let meta = {};

        if (gameMeta) {
            meta = {
                name: gameMeta.name || '',
                description: gameMeta.description,
                tags: gameMeta.categories || [],
                locales: gameMeta.locale, // JSON.parse(gameMeta.locales),
                thumbPath: gameMeta.thumbs.thumbPath,
                thumbPathWebP: this.getWebPImage(gameMeta.thumbs.thumbPath),
                promoThumbPath: gameMeta.thumbs.promoThumbPath,
                promoThumbPathWebP: this.getWebPImage(gameMeta.thumbs.promoThumbPath),
                secondaryThumbPath: gameMeta.thumbs.secondaryThumbPath,
                secondaryThumbPathWebP: this.getWebPImage(gameMeta.thumbs.secondaryThumbPath),
                squareIcon76: gameMeta.thumbs.squareIcon76,
                squareIcon120: gameMeta.thumbs.squareIcon120,
                squareIcon152: gameMeta.thumbs.squareIcon152,
                squareIcon167: gameMeta.thumbs.squareIcon167,
                squareIcon180: gameMeta.thumbs.squareIcon180,
                squareIcon192: gameMeta.thumbs.squareIcon192,
                squareIcon512: gameMeta.thumbs.squareIcon512,
                squareVideo152: gameMeta.thumbs.squareVideo152,
                width: gameMeta.width,
                height: gameMeta.height,
                screenshotPath: gameMeta.thumbs.screenshotPath,
                releaseTime: gameMeta.releaseTime,
                isNew: Game.isGameNew(gameMeta.releaseTime),
                rules: gameMeta.rules,
                metaHTMLTitle: gameMeta.metaHTMLTitle,
                metaHTMLDescription: gameMeta.metaHTMLDescription,
                HTMLHeading: gameMeta.HTMLHeading,
                isFlex: gameMeta.isFlex,
                isGrm: gameMeta.isGRM,
                isIframe: gameMeta.isIframe,
                isAdsFree: gameMeta.isAdsFree,
                hasEagle: gameMeta.hasEagle
            };
        }

        const game = new Game({
            slug: _game.slug,
            alias: gameMeta.alias || _game.slug,
            sdkName: _game.sdkName,
            version: _game.version,
            isCategory: false,
            renderingType: _game.renderingType,
            ...meta
        });

        game.updateAssetsOriginURL();
        return game;
    }

    public static Parse({ feedMetadata, ...gameData }: GameFeedContent): Game {
        const game = new Game({
            slug: gameData.slug,
            alias: feedMetadata.alias || gameData.slug,
            name: feedMetadata.name || gameData.name,
            description: feedMetadata.description || gameData.description,
            sdkName: gameData.sdkName,
            version: gameData.version,
            tags: feedMetadata.categories.length ? feedMetadata.categories : JSON.parse(gameData.tags),
            locales: JSON.parse(gameData.locales),
            thumbPath: gameData.thumbPath,
            thumbPathWebP: this.getWebPImage(gameData.thumbPath),
            promoThumbPath: gameData.promoThumbPath,
            promoThumbPathWebP: this.getWebPImage(gameData.promoThumbPath),
            secondaryThumbPath: gameData.secondaryThumbPath,
            secondaryThumbPathWebP: this.getWebPImage(gameData.secondaryThumbPath),
            squareIcon76: gameData.squareIcon76,
            squareIcon120: gameData.squareIcon120,
            squareIcon152: gameData.squareIcon152,
            squareIcon167: gameData.squareIcon167,
            squareIcon180: gameData.squareIcon180,
            squareIcon192: gameData.squareIcon192,
            squareIcon512: gameData.squareIcon512,
            squareVideo152: gameData.squareVideo152,
            width: gameData.width,
            height: gameData.height,
            screenshotPath: gameData.screenshotPath,
            releaseTime: gameData.releaseTime,
            isNew: Game.isGameNew(gameData.releaseTime),
            rules: feedMetadata.rules,
            metaHTMLTitle: gameData.metaHTMLTitle,
            metaHTMLDescription: gameData.metaHTMLDescription,
            HTMLHeading: gameData.HTMLHeading,
            isCategory: false,
        });

        game.updateAssetsOriginURL();

        return game;
    }

    public static isGameNew(releaseTime) {
        const elapsed = Date.now() - (releaseTime || 0);
        const elapsedDays = Math.floor(elapsed / (1000 * 24 * 60 * 60));

        return elapsedDays <= 14;
    }

    public slug: string;
    public alias: string;
    public name: string;
    public description: string;
    public sdkName: string;
    public version: string;
    public renderingType: 'JavaScript' | 'Html' | 'External';
    public tags: string[];
    public locales: string[];
    public thumbPath: string;
    public thumbPathWebP: string;
    public promoThumbPath: string;
    public promoThumbPathWebP: string;
    public secondaryThumbPath: string;
    public secondaryThumbPathWebP: string;
    public squareIcon76?: string;
    public squareIcon120?: string;
    public squareIcon152?: string;
    public squareIcon167?: string;
    public squareIcon180?: string;
    public squareIcon192?: string;
    public squareIcon512?: string;
    public squareVideo152?: string;
    public width?: number;
    public height?: number;
    public screenshotPath: string;
    public releaseTime: number;
    public isNew: boolean;
    public rules: string;
    public assetOriginUrl: string;
    public thumbOriginUrl: string;
    public metaHTMLTitle: string;
    public metaHTMLDescription: string;
    public HTMLHeading: string;
    public customEventGameName?: string;
    public customEventGameThumb?: string;
    public customEventGameThumbWebp?: string;
    public isCategory?: boolean;
    public buttonText?: string;
    public isFlex: boolean;
    public isAdsFree: boolean;
    public isGrm: boolean;
    public isIframe: boolean;
    public hasEagle: boolean;

    constructor(data) {
        this.slug = data.slug;
        this.alias = data.alias;
        this.name = data.name;
        this.description = data.description;
        this.sdkName = data.sdkName;
        this.version = data.version;
        this.tags = data.tags || [];
        this.locales = data.locales || [];
        this.thumbPath = data.thumbPath;
        this.thumbPathWebP = data.thumbPathWebP;
        this.promoThumbPath = data.promoThumbPath;
        this.promoThumbPathWebP = data.promoThumbPathWebP;
        this.secondaryThumbPath = data.secondaryThumbPath;
        this.secondaryThumbPathWebP = data.secondaryThumbPathWebP;
        this.squareIcon76 = data.squareIcon76;
        this.squareIcon120 = data.squareIcon120;
        this.squareIcon152 = data.squareIcon152;
        this.squareIcon167 = data.squareIcon167;
        this.squareIcon180 = data.squareIcon180;
        this.squareIcon192 = data.squareIcon192;
        this.squareIcon512 = data.squareIcon512;
        this.screenshotPath = data.screenshotPath;
        this.squareVideo152 = data.squareVideo152;
        this.width = data.width;
        this.height = data.height;
        this.releaseTime = data.releaseTime;
        this.isNew = data.isNew;
        this.rules = data.rules;
        this.assetOriginUrl = data.assetOriginUrl;
        this.thumbOriginUrl = data.thumbOriginUrl;
        this.metaHTMLTitle = data.metaHTMLTitle;
        this.metaHTMLDescription = data.metaHTMLDescription;
        this.HTMLHeading = data.HTMLHeading;
        this.renderingType = data.renderingType || 'JavaScript';
        this.isCategory = false;
        this.isFlex = data.isFlex;
        this.isGrm = data.isGrm;
        this.isIframe = data.isIframe;
        this.hasEagle = data.hasEagle;
        this.isAdsFree = data.isAdsFree;
    }

    public hasCategory(category: string): boolean {
        return this.tags && this.tags.some((tag) => tag.toLowerCase() === category.toLowerCase());
    }

    public updateAssetsOriginURL() {
        if (!this.version) {
            throw new Error('Game object has insufficient data to build assets url.');
        }

        this.assetOriginUrl = this.getAssetsOriginRelativeToOrigin();
        this.thumbOriginUrl = environment.GAMES_THUMBS_BLOB + '/' + this.slug + '/';
    }

    public getAssetsOriginRelativeToOrigin() {
        if (!this.version) {
            throw new Error('Game object has insufficient data to build assets url');
        }

        return `${environment.GAMES_BLOB}/${this.sdkName || this.slug}/${this.version}` + '/';
    }

    public getThumbPath(webpSupported: boolean = false) {
        const thumbPath = webpSupported && this.thumbPathWebP ? this.thumbPathWebP : this.thumbPath;

        return thumbPath.startsWith('http') ? thumbPath : this.assetOriginUrl + '/' + thumbPath;
    }
}
