import { apiFetch, HttpError } from '../../fetch';
import { environment } from '../config/environment';
import { KeysEnum } from '../models/Enums';
import { User } from '../models/User';
import { LocalStorageService } from './LocalStorage';
import { CookieStorageService } from './CookieStorage';
import { OpenWebLogout } from './OpenWebService';

const API_PATH = environment.USER_API;

export enum LocalStorageSKeys {
  apiToken = 'arenax_api_token',
  eagleApiToken = 'eagle-access-token',
  eagleRefreshToken = 'eagle-refresh-token',
  eagleExpiresIn = 'eagle-expires_in',
  gdprAccepted = 'gdpr-accepted',
  gemPackId = 'gem-pack-id',
}

export type GdprAcceptedStorage = { [id: string]: boolean };

class AuthService {
  authFetch(url, options?) {
    options = options || {};
    options.headers = options.headers || new Headers();
    options.headers.set('Authorization', `Bearer ${this.getToken()}`);

    return apiFetch(url, options);
  }

  async fetchUser(): Promise<User> {
    try {
      const res = await this.authFetch(`${API_PATH}/user`);

      return {
        ...res,
        apiToken: this.getToken(),
      } as User;
    } catch (err) {
      if (err instanceof HttpError && err.response.status === 404) {
        this.logout();
        throw new Error('User not found');
      }

      throw err;
    }
  }

  logout() {
    LocalStorageService.removeItem(LocalStorageSKeys.apiToken);
  }

  public userLogoutOpenweb(): void {
    if ((window as any).SPOTIM) {
      if ((window as any).SPOTIM.logout) {
        (window as any).SPOTIM.logout();
      } else {
        document.addEventListener('spot-im-api-ready', OpenWebLogout, false);
      }
    }
  }

  getTokenFromUrl(): string {
    const search = new URLSearchParams(window.location.search);

    return search.get(KeysEnum.arkToken);
  }

  getTokenFromCookie(): string {
    const token = CookieStorageService.get(LocalStorageSKeys.apiToken);

    if (!token || token === 'undefined' || token === 'null') {
      return null;
    }

    return token;
  }

  getToken(): string {
    return LocalStorageService.getItem(LocalStorageSKeys.apiToken);
  }

  setToken(token: string): void {
    LocalStorageService.setItem(LocalStorageSKeys.apiToken, token, true);
  }
}

export const authService = new AuthService();
