import { ActionTypes } from '../../models/Analytics';
import { Game } from '../../models/Game';
import { PrerollType } from '../../molecules/Preroll/Preroll';
import { AnalyticsInit } from './AnalyticsInit';
import { AppInsightsAnalytics } from './AppInsights';
import { GoogleAnalytics } from './Google';

class AnalyticsGamePageClass {
    private clickCounts: any = {};

    gameDownload(duration: number) {
        const event = {
            action: `${duration}`,
            category: 'Funnel_Game_Download',
            label: AnalyticsInit.gameSlug,
            nonInteraction: true,
        };

        GoogleAnalytics.trackEvent(event);
    }

    prerollButton(actionType: string) {
        const event = {
            action: actionType,
            category: 'Funnel_PreRoll_Button',
            label: AnalyticsInit.gameSlug,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);
    }

    prerollRequest(prerollType = PrerollType.PREROLL) {
        const prerollTypeMap = new Map();
        const ga = 0,
            ai = 1;

        prerollTypeMap[PrerollType.PREROLL] = ['Funnel_PreRoll_Request', 'funnelPrerollRequest'];
        prerollTypeMap[PrerollType.INTERSTITIAL] = ['Funnel_Interstitial_Request', 'funnelInterstitialRequest'];
        prerollTypeMap[PrerollType.REWARD] = ['Funnel_Reward_Request', 'funnelRewardRequest'];
        const event = {
            action: ActionTypes.IMPRESSION,
            category: prerollTypeMap[prerollType][ga],
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: true,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            nonInteraction: true,
            ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
        };

        AppInsightsAnalytics.trackEvent(prerollTypeMap[prerollType][ai], eventAppInsight);
    }

    prerollEnd(duration: number, prerollType = PrerollType.PREROLL, isDuplicate: boolean = false) {
        const prerollTypeMap = new Map();
        const ga = 0;
        const ai = 1;

        prerollTypeMap[PrerollType.PREROLL] = ['Funnel_PreRoll_End', 'funnelPrerollEnd'];
        prerollTypeMap[PrerollType.INTERSTITIAL] = ['Funnel_Interstitial_End', 'funnelInterstitialEnd'];
        prerollTypeMap[PrerollType.REWARD] = ['Funnel_Reward_End', 'funnelRewardEnd'];
        prerollTypeMap[PrerollType.MIDROLL] = ['Funnel_MidRoll_End', 'funnelMidrollEnd'];

        const event = {
            action: `${AnalyticsInit.gameSlug}`,
            category: this._setDuplicateLabel(prerollTypeMap[prerollType][ga], isDuplicate),
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: true,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            nonInteraction: true,
            eventDuration: duration,
            ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
        };

        AppInsightsAnalytics.trackEvent(
            this._setDuplicateLabel(prerollTypeMap[prerollType][ai], isDuplicate),
            eventAppInsight
        );
    }

    _setDuplicateLabel(eventName: string, isDuplicate: boolean) {
        return isDuplicate ? eventName + '_Duplicate' : eventName;
    }

    adBlock(actionType: string) {
        const event = {
            action: actionType,
            category: 'Adblock Popup',
            label: AnalyticsInit.gameSlug,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        AppInsightsAnalytics.trackEvent('adBlockPopup', eventAppInsight);
    }

    gameStart() {
        const gameSlug = AnalyticsInit.gameSlug;
        const cookieValue = window.sessionStorage.getItem('arkGamePlayHistory');
        const playHistory = cookieValue ? JSON.parse(cookieValue) || [] : [];

        if (gameSlug && playHistory.indexOf(gameSlug) === -1) {
            playHistory.push(gameSlug);
        }

        window.sessionStorage.setItem('arkGamePlayHistory', JSON.stringify(playHistory));

        const event = {
            action: gameSlug,
            category: 'Funnel_Content_Start',
            label: `${AnalyticsInit.domain} | ${gameSlug}`,
            nonInteraction: false,
            dimension13: playHistory.join('|'),
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            nonInteraction: false,
            gameSequence: playHistory.join('|'),
            ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
        };

        AppInsightsAnalytics.trackEvent('funnelContentStart', eventAppInsight);
    }

    gameResume() {
        const gameSlug = AnalyticsInit.gameSlug;
        const cookieValue = window.sessionStorage.getItem('arkGamePlayHistory');
        const playHistory = cookieValue ? JSON.parse(cookieValue) || [] : [];
        const event = {
            action: gameSlug,
            category: 'Funnel_Content_Resume',
            label: `${AnalyticsInit.domain} | ${gameSlug}`,
            nonInteraction: false,
            dimension13: playHistory.join('|'),
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            nonInteraction: false,
            gameSequence: playHistory.join('|'),
        };

        AppInsightsAnalytics.trackEvent('funnelContentResume', eventAppInsight);
    }

    gameEnd(time = 0, score) {
        const gaEvent = {
            action: `${AnalyticsInit.gameSlug}|${time}`,
            category: 'Funnel_Content_End',
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: false,
        };
        const gaImpression = {
            action: AnalyticsInit.gameSlug,
            category: 'Funnel_End_Screen',
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(gaEvent);
        GoogleAnalytics.trackEvent(gaImpression);

        const eventAppInsight = {
            nonInteraction: false,
            eventDuration: time,
            score,
            ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
        };

        AppInsightsAnalytics.trackEvent('funnelContentEnd', eventAppInsight);
    }

    playAgain(actionType) {
        if (actionType === ActionTypes.CLICK) {
            if (this.clickCounts.trackPlayAgain) {
                return false;
            }

            this.clickCounts.trackPlayAgain = true;
        }

        const event = {
            action: actionType,
            category: 'Funnel_End_Button_PlayAgain',
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);

        // don't send the event on impression
        if (actionType !== ActionTypes.IMPRESSION) {
            const eventAppInsight = {
                action: actionType,
                nonInteraction: actionType === ActionTypes.IMPRESSION,
                ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
            };

            AppInsightsAnalytics.trackEvent('funnelEndButtonPlayAgain', eventAppInsight);
        }
    }

    relatedGames(actionType: string, gamesTiles: Game[], gameClicked?: string) {
        if (actionType === ActionTypes.CLICK) {
            if (this.clickCounts.relatedGames) {
                return false;
            }

            this.clickCounts.relatedGames = true;
        }

        const event = {
            action: actionType,
            category: 'Funnel_End_WhyNotTryThese',
            label: `${AnalyticsInit.domain} | game end | ${AnalyticsInit.gameSlug}`,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            recommendedGamesClick: gameClicked,
            recommendedGamesImpression: gamesTiles.length ? gamesTiles.map((tile) => tile.slug).join('|') : undefined,
        };

        AppInsightsAnalytics.trackEvent('recommendedGames', eventAppInsight);
    }

    levelUp(levelUpId: number) {
        const event = {
            action: ActionTypes.IMPRESSION,
            category: 'Level Up',
            label: `Level ${levelUpId}`,
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: ActionTypes.IMPRESSION,
            nonInteraction: false,
            levelUpId,
        };

        AppInsightsAnalytics.trackEvent('levelUp', eventAppInsight);
    }

}

export const AnalyticsGamePage = new AnalyticsGamePageClass();
