import { ActionTypes } from '../../models/Analytics';
import { UrlService } from '../UrlService';
import { AnalyticsInit } from './AnalyticsInit';
import { AppInsightsAnalytics } from './AppInsights';
import { GoogleAnalytics } from './Google';

class AnalyticsHomePageClass {
    private clickCounts: any = {};

    promoGame(actionType: string, slug: string) {
        if (actionType === ActionTypes.CLICK) {
            if (this.clickCounts.promoGame) {
                return false;
            }

            this.clickCounts.promoGame = true;
        }

        const event = {
            action: actionType,
            category: 'Carousel',
            label: `${AnalyticsInit.domain} | arena home`,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            dimension5: actionType === ActionTypes.CLICK ? slug : undefined,
            dimension6: actionType === ActionTypes.IMPRESSION ? slug : undefined,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: actionType,
            game: '',
            promotedGame: slug,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        AppInsightsAnalytics.trackEvent('bigPromo', eventAppInsight);

        return event;
    }

    redesignedPromobannerClicks(actionType: string, slug: string, isTitle = false) {
        const eventAppInsight = {
            action: actionType,
            promotedGame: slug,
            nonInteraction: false,
        };
        const evemtName = isTitle ? 'promobox' : 'promoboxSlot';

        AppInsightsAnalytics.trackEvent(evemtName, eventAppInsight);
    }

    tilesSections(actionType: string, eventCategory: string, tags: string) {
        const event = {
            action: actionType,
            category: eventCategory,
            label: `${AnalyticsInit.domain} | ${UrlService.getCurrentPage()}`,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            dimension7: eventCategory === 'Promo_Box' ? tags : undefined,
            dimension8: eventCategory === 'Arena_Promo_Box' ? tags : undefined,
            dimension9: eventCategory === 'Promo_Box_Slot' ? tags : undefined,
        };

        GoogleAnalytics.trackEvent(event);

        const appInsightCategory = eventCategory === 'Promo_Box_Slot' ? 'promoboxSlot' : 'promobox';
        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            promoboxClick: eventCategory === 'Promo_Box' ? tags : undefined,
            promoboxImpression: eventCategory === 'Arena_Promo_Box' ? tags : undefined,
            promoboxSlotClick: eventCategory === 'Promo_Box_Slot' ? tags : undefined,
        };

        AppInsightsAnalytics.trackEvent(appInsightCategory, eventAppInsight);
    }

    fTuxAnalytics(actionType: string, category: string, catName?: string) {
        const event = {
            action: actionType,
            category,
            label: catName,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            ftuxWelcomeClick: catName,
        };

        AppInsightsAnalytics.trackEvent(category, eventAppInsight);
    }
}

export const AnalyticsHomePage = new AnalyticsHomePageClass();
