import { MiscUtils } from '../../utils/MiscUtils';
import { AnalyticsEventBuilder } from './Analytics/AnalyticsEventBuilder';
import { UrlService } from './UrlService';

export class AppInsightService {
    static init() {
        this.trackPageView();
    }

    static trackAppError(error, info = {}) {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackEvent('js-error', {
                error,
                info: JSON.stringify(info),
                adBlockEnabled: MiscUtils.isAdBlockEnabled(),
            });
        }
    }

    static trackPageView() {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackPageView({
                name: document.title,
                uri: UrlService.convertEntities(window.location.href),
                properties: { ...new AnalyticsEventBuilder().pageViewEvent().options, pagePath: window.location.pathname },
                screenResolution: window.innerWidth + 'x' + window.innerHeight,
            });
        }
    }

    static trackEvent(name: string, data: any) {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackEvent({
                name,
                properties: { ...data, pagePath: window.location.pathname  },
            });
        }
    }
}
