export const AB_R2_DESKTOP_SIZE = 'ad_r2_desktop_size';

export class ABTests {
    abtest_Homepage?: 'Control' | 'Redesigned'; // Homepage new design #123647
    // tslint:disable-next-line:max-line-length
    // https://www.figma.com/file/5gatXkXe1o0hVK6HGvE9j1/Homepage?node-id=1%3A2

    // https://arkadiumarena.visualstudio.com/Arena/_workitems/edit/143096
    abtest_Lightbox?: 'Control' | 'Lightbox';

    // https://arkadiumarena.visualstudio.com/Arena/_workitems/edit/148166
    abtest_Xfinity?: 'Ark' | 'Arena';
}

export type ABTestsSettings = {
    [K in keyof ABTests]: Array<{ val: ABTests[K]; weight: number }>;
};

export type ABTestsSettingsConfig = { ver: string } & {
    [K in keyof ABTests]: Array<{ weight: number; val: any }> & Array<{ val: ABTests[K] }>;
};

export const TYPES = new ABTests();
