import { Game } from '../models/Game';
import { AssetService } from './AssetService';
import { DeviceDetector } from './DeviceDetector';

const FB_APP_ID = '1429736807162095';
const DEFAULT_IMAGE_PATH = '/social/600315.jpg';

export class SEOService {
    static buildMeta(
        metaDescription: string,
        canonical: string,
        clientName: string,
        absoluteBaseHref: string,
        sharedImage?: string
    ) {
        const origin = new URL(absoluteBaseHref).origin;

        return [
            {
                name: 'description',
                content: metaDescription,
            },
            {
                property: 'og:url',
                content: canonical,
            },
            {
                property: 'og:title',
                content: clientName,
            },
            {
                property: 'og:description',
                content: metaDescription,
            },
            {
                property: 'og:image',
                content: AssetService.sharedRelativeToOrigin(origin, sharedImage || DEFAULT_IMAGE_PATH),
            },
            {
                property: 'og:image:width',
                content: '600',
            },
            {
                property: 'og:image:height',
                content: '315',
            },
            {
                property: 'og:type',
                content: 'article',
            },
            {
                property: 'og:site_name',
                content: `${clientName}`,
            },
            {
                property: 'fb:app_id',
                content: FB_APP_ID,
            },
            {
                property: 'twitter:card',
                content: 'summary',
            },
            {
                property: 'twitter:title',
                content: clientName,
            },
            {
                property: 'twitter:description',
                content: metaDescription,
            },
            {
                property: 'twitter:image',
                content: AssetService.sharedRelativeToOrigin(origin, sharedImage || DEFAULT_IMAGE_PATH),
            },
            DeviceDetector.isIOS()
                ? { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' }
                : {},
        ];
    }

    static buildMetaForGamePage(
        metaDescription: string,
        game: Game,
        canonical: string,
        gamePageSocialMetaTitle: string,
        clientName: string,
        absoluteBaseHref: string
    ) {
        const origin = new URL(absoluteBaseHref).origin;

        return [
            {
                name: 'description',
                content: metaDescription,
            },
            {
                property: 'og:url',
                content: canonical,
            },
            {
                property: 'og:title',
                content: gamePageSocialMetaTitle,
            },
            {
                property: 'og:description',
                content: game.description,
            },
            {
                property: 'og:image',
                content: game.getAssetsOriginRelativeToOrigin() + game.secondaryThumbPath,
            },
            {
                property: 'og:image:width',
                content: '600',
            },
            {
                property: 'og:image:height',
                content: '600',
            },
            {
                property: 'og:type',
                content: 'article',
            },
            {
                property: 'og:site_name',
                content: `Arena ${clientName}`,
            },
            {
                property: 'fb:app_id',
                content: FB_APP_ID,
            },
            {
                property: 'twitter:card',
                content: 'summary',
            },
            {
                property: 'twitter:title',
                content: gamePageSocialMetaTitle,
            },
            {
                property: 'twitter:description',
                content: game.description,
            },
            {
                property: 'twitter:image',
                content: game.getAssetsOriginRelativeToOrigin() + game.thumbPath,
            },
            DeviceDetector.isIOS()
                ? { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' }
                : {},
        ];
    }
}
