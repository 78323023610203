import Fuse from 'fuse.js';
import i18n from 'i18next';
import { Game } from '../models/Game';
import { HeaderSearchResult } from '../models/HeaderSearchResult';
import { ImageService } from './ImageService';
import { UrlService } from './UrlService';

export class SearchService {
    public static prepareSearchParam(searchParam: string): string {
        return (searchParam || '').toString().toLowerCase().trim();
    }

    public static quickSearch(games: Game[] = [], searchTerm: string = ''): Promise<HeaderSearchResult[]> {
        if (!searchTerm) {
            return Promise.resolve([]);
        }

        const fuse = this.getFuse(games);
        const foundGames = fuse.search(searchTerm).slice(0, 15);
        const baseHref = UrlService.getWindowBaseHref();

        return ImageService.isWebpSupported().then((webpSupported) => {
            return foundGames.map((game) => ({
                slug: game.slug,
                title: game.name,
                url: `${baseHref}/${i18n.t('ROUTES.GAMES')}/${game.alias}`,
                // TODO: Add webp
                image: game.getThumbPath(),
            }));
        });
    }

    private static fuse: any = null;

    private static getFuse(games: Game[]) {
        if (!this.fuse) {
            this.fuse = new Fuse(games, { keys: ['name'], threshold: 0.5 });
        }

        return this.fuse;
    }
}
