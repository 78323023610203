import React from 'react';
import { connect } from 'react-redux';
import { ActionTypes } from '../../models/Analytics';
import { LeaderboardRecord } from '../../models/LeaderboardRecord';
import { LoginCaller, LoginState } from '../../models/Login';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { setLoginDialog } from '../../store/ducks/loginDialog';
import { ELoginSteps, setLoginModal } from '../../store/ducks/modal';
import { LeaderboardRow } from '../LeaderboardRow/LeaderboardRow';
import styles from './LeaderboardList.css';

interface LeaderboardListProps {
    list: LeaderboardRecord[];
    user: User | EagleUser;
    userAuthStatus: UserAuthStatus;
    userScore: number;
    hasUserPlayed: boolean;
    dispatch: any;
}

const DELIMITER_POSITION = 6;

class LeaderboardListBase extends React.PureComponent<LeaderboardListProps> {
    componentDidMount() {
        if (this.props.userAuthStatus === UserAuthStatus.USER_NOT_AUTHORIZED) {
            AnalyticsGeneral.login(ActionTypes.IMPRESSION, LoginCaller.Leaderboard);
        }
    }

    componentDidUpdate(prevProps: LeaderboardListProps) {
        if (prevProps.userAuthStatus !== this.props.userAuthStatus) {
            if (this.props.userAuthStatus === UserAuthStatus.USER_NOT_AUTHORIZED) {
                AnalyticsGeneral.login(ActionTypes.IMPRESSION, LoginCaller.Leaderboard);
            }
        }
    }

    onLoginClick = () => {
        // this.props.dispatch(setLoginDialog({ show: true, state: LoginState.Login, caller: LoginCaller.Leaderboard }));
        this.props.dispatch(setLoginModal({ isOpen: true, step: ELoginSteps.LOGIN, caller: LoginCaller.Leaderboard }));
    };

    render() {
        const { user, userAuthStatus, hasUserPlayed, list } = this.props;

        list.forEach((item, i, arr) => {
            (arr[i] as any).position = i + 1;
        });

        const indexIn100 = user ? list.findIndex((item) => item.name === user.name) : -1;
        const position = indexIn100 !== -1 && indexIn100 <= 99 ? indexIn100 + 1 : undefined;

        return this.renderTable(list, user, userAuthStatus, hasUserPlayed, position);
    }

    renderTable(top100, user, userAuthStatus, hasUserPlayed, userPosition?) {
        let records = [];
        let showDelimiter = false;
        const showYouCanBeHere = !user || (user && !hasUserPlayed);

        if (!user || (user && !hasUserPlayed) || userPosition <= 9) {
            // Only one group
            records = top100.slice(0, 9);
        } else {
            // Two groups
            showDelimiter = true;
            records = top100.slice(0, 6);

            let afterDelimiter: LeaderboardRecord[];

            if (userPosition) {
                // Show +1, user, -1
                afterDelimiter = top100.slice(userPosition - 2, userPosition + 1);
            } else {
                // Show 99,100 and 100+ as user
                afterDelimiter = top100.slice(-3, top100.length - 1);
                afterDelimiter.push(LeaderboardRecord.FromUserAndScore(user, this.props.userScore || 0));
            }

            records.push(...afterDelimiter);
        }

        return (
            <React.Fragment>
                {showYouCanBeHere && (
                    <LeaderboardRow userAuthStatus={userAuthStatus} user={user} onLoginClick={this.onLoginClick} />
                )}

                {records.map((row, i) => (
                    <React.Fragment key={i}>
                        <LeaderboardRow key={row.name} user={user} userAuthStatus={userAuthStatus} row={row} />

                        {showDelimiter && DELIMITER_POSITION === row.position ? <Delimiter /> : null}
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }
}

const Delimiter = ({ children, props }: any) => (
    <div className={styles.delimiter} {...props}>
        ...
    </div>
);

export const LeaderboardList = connect()(LeaderboardListBase);
