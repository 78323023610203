import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { ArenaConfig } from '../models/ArenaConfig';
import { GameState } from '../models/Enums';
import { Game } from '../models/Game';
import AdsService from '../services/AdsService';
import { AnalyticsInit } from '../services/Analytics/AnalyticsInit';
import { LDJsonTemplateService } from '../services/LDJsonTemplateService';
import { PwaService } from '../services/PwaService';
import { SEOService } from '../services/SEOService';
import { UrlService } from '../services/UrlService';
import { setGame } from '../store/ducks/game';
import { gameByAliasSelector, gamesByLangSelector } from '../store/ducks/games';
import { set404Status } from '../store/ducks/is404';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { GameTemplate } from '../templates/Game/GameTemplate';
import { Page404 } from './Page404';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';
import { EagleUser, User } from '../models/User';
import { UserAuthStatus } from '../models/UserAuthStatus';

type GamePageProps = {
    absoluteBaseHref: string;
    config: ArenaConfig;
    gameState: GameState;
    game: Game;
    games: Game[];
    lang: string;
    match: any;
    customVersion?: string;
    customApi?: string;
    isAdsEnabled: boolean;
    t: any;
    dispatch?: any;
    arenaLang: string;
    user: User | EagleUser;
    userAuthStatus: UserAuthStatus;
};

class GamePageBase extends React.PureComponent<GamePageProps & WithTranslation> {
    constructor(props) {
        super(props);
        const { lang, game } = props;

        if (game) {
            this.props.dispatch(setGame(this.props.game));

            AnalyticsInit.setGameSlug(game.slug);
            AnalyticsInit.setGlobalCustomDimensions({}, {gameRelease: game.version});
            PwaService.createManifest(lang, null, game);
        }
    }

    getMeta(canonical) {
        const { game, t, config } = this.props;
        const gamePageSocialMetaTitle = t('GAME_SOCIAL_META_TITLE', {
            gameName: game.name,
            clientName: config.theme.name,
        });
        const clientName = config.theme.name;
        // if the value of GAME_META_DESCRIPTION is only a game name then we consider this text is empty
        const commonMetaDescCustomValue = t('GAME_META_DESCRIPTION', { gameName: game.name });
        const commonMetaDescCustom = commonMetaDescCustomValue === game.name ? '' : commonMetaDescCustomValue;
        const metaDescription = game.metaHTMLDescription || commonMetaDescCustom || game.description;

        return SEOService.buildMetaForGamePage(
            metaDescription,
            this.props.game,
            canonical,
            gamePageSocialMetaTitle,
            clientName,
            this.props.absoluteBaseHref
        );
    }

    set404Status = () => {
        this.props.dispatch(set404Status(true));
    };

    render() {
        const {
            absoluteBaseHref,
            config,
            game,
            gameState,
            games,
            customVersion,
            customApi,
            lang,
            t,
            isAdsEnabled,
            user,
            userAuthStatus,
        } = this.props;

        if (!game) {
            this.set404Status();
            return <Page404 />;
        }

        if (customApi) {
            game.assetOriginUrl = customApi;
        } else if (customVersion) {
            game.version = customVersion;
            game.updateAssetsOriginURL();
        }

        const commonMetaTitle = t('GAME_META_TITLE', { gameName: game.name, clientName: config.theme.name });
        const specificGameMetaTitle = game.metaHTMLTitle;
        const metaTitle = specificGameMetaTitle || commonMetaTitle;
        const subpath = ['ROUTES.GAMES', game.alias];
        const canonical = `${absoluteBaseHref}/${t(subpath[0])}/${subpath[1]}`;
        const LDJsonData = LDJsonTemplateService.buildGameTemplate(game, absoluteBaseHref, lang);
        const seoHreflangLinks = generateSeoHreflangLinksList(
            absoluteBaseHref,
            this.props.arenaLang,
            subpath);

        return (
            <ErrorBoundary>
                <ConnectedHelmet
                    title={metaTitle}
                    meta={this.getMeta(canonical)}
                    link={[
                        { rel: 'canonical', href: canonical },
                        { rel: 'preload', href: AdsService.getVideoAdPath(config.ad), as: 'script' },
                        ...seoHreflangLinks,
                    ]}
                >
                    <script async type="application/ld+json">
                        {JSON.stringify(LDJsonData)}
                    </script>
                </ConnectedHelmet>

                <GameTemplate
                    isAdsEnabled={isAdsEnabled}
                    gameState={gameState}
                    game={game}
                    games={games}
                    config={config}
                    user={user}
                    userAuthStatus={userAuthStatus}
                />
            </ErrorBoundary>
        );
    }
}

const GamePageTranslated = withTranslation()(GamePageBase);

export const GamePage = connect((state: AppState, props: RouteComponentProps<any>) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
    config: state.config,
    gameState: state.gameState,
    game: gameByAliasSelector(state, props),
    games: gamesByLangSelector(state),
    customVersion: UrlService.getCustomVersionParam(props),
    customApi: UrlService.getGameCustomApi(props),
    lang: state.currentLang,
    isAdsEnabled: state.config.theme.shouldShowDisplayAd(),
    arenaLang: state.config.theme.locale,
    user: state.user,
    userAuthStatus: state.userAuthStatus,
}))(GamePageTranslated);
