import React from 'react';
import { isServer } from '../../../utils';
import { ABTests } from '../../models/ABTests';
import { ABTestManager } from './ABTestManager';
import { QuantcastService } from '../QuantcastService';

export const ABTestContext = React.createContext(new ABTestManager());

export function useABTest<K extends keyof ABTests>(testName: K, defaultValue: ABTests[K]): ABTests[K] {
    const abtests = React.useContext(ABTestContext);
    const result = abtests.getVariation(testName, defaultValue);

    if (!isServer) {
        const { name, value, options } = abtests.getCookie();

        if (options && options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        const cookie = [
            `${name}=${encodeURIComponent(value)}`,
            ...Object.entries(options).map((kv) => kv.join('=')),
        ].join('; ');
        const isQuantcastEnabled = QuantcastService.isEnabled;

        if ((isQuantcastEnabled && !QuantcastService.blockedByGdprConsent()) || !isQuantcastEnabled) {
            document.cookie = cookie;
        }
    }

    return result;
}

export function ABTestProvider({ children, manager }: React.PropsWithChildren<ABTestProviderProps>) {
    return <ABTestContext.Provider value={manager}>{children}</ABTestContext.Provider>;
}

interface ABTestProviderProps {
    manager: ABTestManager;
}
