import { TFunction } from 'i18next';
import nodeFetch from 'node-fetch';
import { Response } from 'express';
import { checkFetchStatus } from '../../fetch';
import { hostnameToArenaDomain } from '../../utils';
import { environment } from '../config/environment';
import { RedirectSettings, RedirectsSettingsData } from '../models/Redirects';

const DEFAULT_REDIRECT_TYPE = 301;

export class RedirectsService {
    public static getUrlRedirect(url: URL, redirects: RedirectSettings[]): RedirectSettings {
        const domain = hostnameToArenaDomain(url.hostname);
        const pathname = url.pathname.replace(/\/$/, '');
        const decodedPathname = decodeURI(pathname);
        const matchedRedirect = redirects.find((redirect) => redirect.from === decodedPathname);
        const isRedirect =
            matchedRedirect && (!matchedRedirect.exclude || matchedRedirect.exclude.indexOf(domain) === -1);

        if (isRedirect) {
            matchedRedirect.type = +matchedRedirect.type || DEFAULT_REDIRECT_TYPE;
            const msgParam = matchedRedirect.message ? `?redirmsg=${matchedRedirect.message}` : '';

            matchedRedirect.to = matchedRedirect.to + msgParam;
            return matchedRedirect;
        } else {
            return null;
        }
    }

    public static async fetchRedirectsSettings(): Promise<RedirectsSettingsData> {
        try {
            const res: Response = await nodeFetch(environment.REDIRECTS_SETTINGS_PATH, { timeout: 20000 });
            const response = checkFetchStatus(res as any);

            return response.json();
        } catch (e) {
            console.log('Error while fetchRedirectsSettings', e);
            throw e;

        }
    }

    public static redirectToTranslatedUrl(
        url: string,
        currentLang: string,
        i18n: { t: TFunction },
        res: Response,
    ): void {
        const checkPatterns = ['/category/', '/games/', '/help', '/profile', '/all-games'];
        const checkIncorrectLocale = ['en-us', 'en-gb', 'en-au', 'en-nz'];
        const incorrectUrlPart = checkPatterns.find((str) => url.includes(str));
        const incorrectLocale = checkIncorrectLocale.find(str => url.includes(str));

        if (incorrectLocale && currentLang === 'en') {
            const replacedUrl = url.replace(incorrectLocale, '').replace('/', '');

            res.redirect(replacedUrl, DEFAULT_REDIRECT_TYPE);
        }

        if (currentLang !== 'en' && incorrectUrlPart) {
            const withoutSlashes = incorrectUrlPart.replace(/\//g, '');
            const strToTranslate = withoutSlashes.replace('-', '_').toUpperCase();
            const translatedFirstPart = `/${i18n.t(`ROUTES.${strToTranslate}`)}/`;
            let correctUrl: string;

            if (incorrectUrlPart === '/category/') {
                const category = url
                    .split('/')
                    .filter((e) => e)
                    .pop();
                const categoryTranslated = i18n.t(category.toUpperCase());

                correctUrl = url.replace(incorrectUrlPart, translatedFirstPart).replace(category, categoryTranslated);
            } else {
                correctUrl = url.replace(incorrectUrlPart, translatedFirstPart);
            }

            res.redirect(correctUrl.replace(/\/+$/, ''), DEFAULT_REDIRECT_TYPE);
        }
    }
}
