import classnames from 'classnames';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { withSSR } from 'react-i18next';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import './App.css';
import { ErrorBoundary } from './client/atoms/ErrorBoundary';
import { RouterWrapper } from './client/atoms/RouterWrapper';
import { ActionTypes, CustomCategories } from './client/models/Analytics';
import { ArenaConfig } from './client/models/ArenaConfig';
import { StoreModifiers } from './client/models/StoreModifiers';
import { CookieBanner } from './client/molecules/CookieBanner/CookieBanner';
import { Footer } from './client/organisms/Footer/Footer';
import { ABTestManager } from './client/services/ABTests/ABTestManager';
import { ABTestProvider } from './client/services/ABTests/ABTestReact';
import { ABTestService } from './client/services/ABTestService';
import adsService from './client/services/AdsService';
import { AnalyticsGeneral } from './client/services/Analytics/AnalyticsGeneral';
import { AnalyticsInit } from './client/services/Analytics/AnalyticsInit';
import { LocalStorageSKeys } from './client/services/AuthService';
import { DeviceDetector } from './client/services/DeviceDetector';
import { DisplayAdService } from './client/services/DisplayAdService';
import { OneTrustService } from './client/services/OneTrustService';
import { PwaService } from './client/services/PwaService';
import { setConfig } from './client/store/ducks/config';
import { restoreUserIfAuthorizedThunk } from './client/store/ducks/user';
import createStore from './client/store/index';
import { AppState } from './client/store/types';
import './custom.scss';
import { defaultNameSpace, i18nClientInit } from './i18n';
import './polyfills';
import { routes } from './routes';
import { isServer } from './utils';
import { QuantcastService } from './client/services/QuantcastService';
import { CookieStorageService } from './client/services/CookieStorage';
import { HeaderSplitWrapper } from './client/organisms/Header/Redesign/HeaderSplitWrapper';
import { KeysEnum } from './client/models/Enums';
import { Snackbar } from './client/FigmaStyleguide/Snackbar/Snackbar';
import { ShopModal } from './client/molecules/ShopModal/ShopModal';
import { LoginModal } from './client/organisms/Login/LoginModal';
import { ABTestedLightboxFeature } from './client/organisms/LightboxPromotionFeature/ABTestedLightboxPromotionFeature';
import { XfinityAnnounceABTest } from './client/organisms/AnnounceBanner/XfinityAnnounceABTest';
import { FingerprintService } from './client/services/Analytics/FingerprintJS/FingerprintService';
import { environment } from './client/config/environment';

void i18nClientInit();

const { store, history } = createStore();

StoreModifiers.useConfigFromLocalStorage(store);
StoreModifiers.applyGlobalGamesSortings(store);

const { abTests, arenaOrigin, texts, currentLang, config } = store.getState() as AppState;
const arenaConfig = new ArenaConfig(config, currentLang);

store.dispatch(setConfig(arenaConfig));

PwaService.activateServiceWorker(config);

QuantcastService.init(config);
OneTrustService.init(config);
ABTestService.init(abTests);

DisplayAdService.init(config.ad).then(() => adsService.initDisplayAds(config.theme.adRefreshTimeMs));

if (!isServer) {
    (window as any).STORE = store;
    (window as any).__ARK__ArenaInfo = {
        arenaVersion: '5.1',
        domain: config.theme.domain,
    };

    // ThemeService.updateCssVariables(DefaultTheme.cssVariables);

    // /* Theme switch */
    // if (arenaConfig.theme.theming.name === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile()) {
    //     ThemeService.updateCssVariables(DigitalTurbineTheme.cssVariables);
    // }
}

(Provider as any).displayName = 'Provider';

export class App extends React.Component {
    static displayName = 'App';

    protected abtests: ABTestManager;

    constructor(props) {
        super(props);
        const state = store.getState();
        const cookie = CookieStorageService.get(KeysEnum.arkabtests);
        const isDevelopmentMode = environment.ENV !== 'live' && environment.ENV !== 'canary';

        this.abtests = new ABTestManager();
        this.abtests.init(state.config.abTestsSettings, cookie);
        AnalyticsInit.init(config.analytics, abTests, state.is404);
        AnalyticsInit.trackPageView(undefined, state.is404);
        /** Debug */
        AnalyticsInit.trackInconsistentArena(arenaOrigin);
        FingerprintService.init(isDevelopmentMode);
    }

    componentDidMount() {
        const state = store.getState() as AppState;

        if (!state.config.isEagle) {
            store.dispatch<any>(restoreUserIfAuthorizedThunk());
        }

        // Added for usat SSO login
        if (state.config.sso?.name === 'usatoday' && state.config.isEagle) {
            store.dispatch<any>(restoreUserIfAuthorizedThunk());
        }

        if (state.config.isEagle && state.config.sso.name === 'hsn') {
            store.dispatch<any>(restoreUserIfAuthorizedThunk());
        }

        try {
            if (!isServer) {
                document.documentElement.dataset.arena_origin =
                    document.documentElement.dataset.arena_origin || (window as any).location.hostname;
            }
        } catch (e) {
            console.log('Error: ', e);
        }

        try {
            if (!isServer) {
                document.documentElement.dataset.arena_origin =
                    document.documentElement.dataset.arena_origin || (window as any).location.hostname;
                document.documentElement.dataset.custom_header =
                    document.documentElement.dataset.custom_header || store.getState()?.config?.theme?.client;
            }
        } catch (e) {
            console.log('Error: ', e);
        }

        if (PwaService.isIosStandalone()) {
            AnalyticsGeneral.pwa(ActionTypes.IOS_BOOKMARK_VISIT, CustomCategories.IOS_BOOKMARK);
        }

        if (PwaService.isAndroidPcStandalone()) {
            if (DeviceDetector.isDesktop()) {
                AnalyticsGeneral.pwa(ActionTypes.VISIT, CustomCategories.DESKTOP_PWA);
            } else {
                AnalyticsGeneral.pwa(ActionTypes.ANDROID_BOOKMARK_VISIT, CustomCategories.ANDROID_BOOKMARK);
            }
        }
    }

    render() {
        return (
            <ABTestProvider manager={this.abtests}>
                <HelmetProvider>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <ErrorBoundary>
                                <RouterWrapper>
                                    <HeaderSplitWrapper store={store} />
                                    <div className={classnames('main-content')}>{renderRoutes(routes())}</div>
                                    <Footer />
                                    {/* <Login /> */}
                                    <Snackbar />
                                    <ShopModal />
                                    <LoginModal />
                                    <ABTestedLightboxFeature />
                                    <XfinityAnnounceABTest />
                                    <CookieBanner currentLang={currentLang} config={config} />
                                </RouterWrapper>
                            </ErrorBoundary>
                        </ConnectedRouter>
                    </Provider>
                </HelmetProvider>
            </ABTestProvider>
        );
    }
}

(function () {
    // Client-side seo-oriented redirect for SSR-proceeded and served requests of urls
    // Firing this check here before main code executes to prevent unnecessary extra page load
    // Even having no 301 server response status it should be proceeded same way by crawlers
    // according to this research: https://searchengineland.com/tested-googlebot-crawls-javascript-heres-learned-220157
    if (!isServer) {
        const originalUrl = window.location.href;
        const originalPath = window.location.pathname;
        const originalUrlExtras = window.location.search || window.location.hash;
        const isSeoRedirect = originalPath.match(/.+\/$/gi) && !originalUrlExtras;
        const userHasToken = localStorage.getItem(LocalStorageSKeys.apiToken);
        const localeUrlRegexpSlashed = new RegExp(`^\/${config.theme?.locale || 'en'}\/`, 'gi');
        const localeUrlRegexpRoot = new RegExp(`^\/${config.theme?.locale || 'en'}`, 'gi');
        const isPageLocaleEqualToMainPage =
            Boolean(originalPath.match(localeUrlRegexpSlashed)) ||
            (Boolean(originalPath.match(localeUrlRegexpRoot)) && originalPath.length === 3); // case of e.g. /fr
        let targetUrl = '';

        if (
            // Firstly check if this is SEO ranking competition between main path and locale path with the same language
            // The check of /-ended url will be done next to prevent use of e.g. /^\/en/gi for '/endgame'-like url
            isPageLocaleEqualToMainPage
        ) {
            // checking for localhost to prevent window.location.assign error thrown locally
            // "Failed to launch ... because the scheme does not have a registered handler."
            targetUrl =
                window.location.origin +
                (originalPath.length === 3
                    ? originalPath.replace(localeUrlRegexpRoot, '')
                    : originalPath.replace(localeUrlRegexpSlashed, '/'));
        }

        if (
            isSeoRedirect &&
            // We have some issue with reauthorize an user if this code is executed
            // So we have to make sure that user (crawler) is not authorized
            !userHasToken
        ) {
            targetUrl = (targetUrl || originalUrl).replace(/\/$/gi, '');
        }

        if (targetUrl) {
            window.location.assign(targetUrl);
        }
    }
})();

const ExtendedApp = withSSR()(App);
const root = document.getElementById('root');

root.innerHTML = '';
ReactDOM.render(
    <ExtendedApp initialLanguage={currentLang} initialI18nStore={{ [currentLang]: { [defaultNameSpace]: texts } }} />,
    root
);

export default App;
