import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { Game } from '../models/Game';
import { LDJsonTemplateService } from '../services/LDJsonTemplateService';
import { PwaService } from '../services/PwaService';
import { SEOService } from '../services/SEOService';
import { UrlService } from '../services/UrlService';
import { gamesByLangSelector } from '../store/ducks/games';
import { set404Status } from '../store/ducks/is404';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { CategoryTemplate } from '../templates/Category/Category';
import { Page404 } from './Page404';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';
import {
    EventCategoryService,
    frenchTextFix,
    generateEventCustomClass,
    generateEventCustomStyles,
    IEventCategory,
} from '../services/EventCategoryService';

type CategoryPageProps = {
    absoluteBaseHref: string;
    allGames: Game[];
    categoryFromUrl: string;
    clientName: string;
    client?: string;
    disablePoweredBy?: boolean;
    location: any;
    lang: string;
    allCategoriesKeys: string[];
    isAdsEnabled: boolean;
    dispatch?: any;
    themeName: string;
    arenaLang: string;
    eventCategoryConfig?: IEventCategory;
    eventCustomStyles?: string;
    eventsCustomClass?: string;
    sharingImage?: string;
};

class CategoryPageBase extends React.PureComponent<CategoryPageProps & WithTranslation> {
    state = {
        games: [],
        isLoading: true,
    };

    private categoryKey: string; // untranslated category
    private category: string; // translated category
    private isCategoryCustom: boolean;

    constructor(props) {
        super(props);
        this.getCurrentCategory();

        if (this.categoryKey) {
            this.createPwaManifest();
            this.state = { games: this.buildGameList(), isLoading: false };
        }
    }

    getMeta(canonical) {
        const { t, clientName, absoluteBaseHref, i18n, sharingImage } = this.props;
        const metaDescription = i18n.exists(`CATEGORY_META_DESCRIPTION_${this.categoryKey.toUpperCase()}`)
            ? t(`CATEGORY_META_DESCRIPTION_${this.categoryKey.toUpperCase()}`)
            : t('CATEGORY_META_DESCRIPTION', { category: this.category.toLowerCase(), clientName });

        return SEOService.buildMeta(metaDescription, canonical, clientName, absoluteBaseHref, sharingImage);
    }

    componentDidUpdate(prevProps: CategoryPageProps) {
        const needUpdate =
            this.props.categoryFromUrl !== prevProps.categoryFromUrl ||
            this.props.allGames.length !== prevProps.allGames.length;

        if (needUpdate) {
            this.getCurrentCategory();

            if (this.categoryKey) {
                this.createPwaManifest();
                this.setState({ games: this.buildGameList(), isLoading: false });
            }
        }
    }

    buildGameList() {
        const { allGames, eventCategoryConfig } = this.props;
        const gamesList = allGames.filter((game) => game.hasCategory(this.category));

        return EventCategoryService.mixinGames(gamesList, eventCategoryConfig, this.category);
    }

    createPwaManifest() {
        const { lang, clientName, t, i18n } = this.props;
        const categoryTitleTranslated = i18n.exists(`CATEGORY_TITLE_${this.categoryKey.toUpperCase()}`)
            ? t(`CATEGORY_TITLE_${this.categoryKey.toUpperCase()}`)
            : this.category;
        const description = i18n.exists(`CATEGORY_META_DESCRIPTION_${this.categoryKey.toUpperCase()}`)
            ? t(`CATEGORY_META_DESCRIPTION_${this.categoryKey.toUpperCase()}`)
            : t('CATEGORY_META_DESCRIPTION', { category: this.category.toLowerCase(), clientName });

        PwaService.createManifest(lang, {
            name: categoryTitleTranslated,
            description,
            iconName: this.isCategoryCustom || this.categoryKey === 'daily' ? 'arena' : this.categoryKey,
        });
    }

    i18nExists(key: string) {
        const { t, i18n } = this.props;

        return i18n.exists(key) && !!t(key);
    }

    render() {
        const { absoluteBaseHref, categoryFromUrl, clientName, lang, t, isAdsEnabled, themeName, client, disablePoweredBy } = this.props;
        const { games } = this.state;

        // Game list is empty or there isn't such category
        if (!games.length || !this.categoryKey) {
            this.props.dispatch(set404Status(true));
            return <Page404 />;
        }

        const caption = this.i18nExists(`CATEGORY_TITLE_${this.categoryKey.toUpperCase()}`)
            ? t(`CATEGORY_TITLE_${this.categoryKey.toUpperCase()}`)
            : this.category;
        const metaTitle = this.i18nExists(`CATEGORY_META_TITLE_${this.categoryKey.toUpperCase()}`)
            ? t(`CATEGORY_META_TITLE_${this.categoryKey.toUpperCase()}`)
            : t('CATEGORY_META_TITLE', { category: caption, clientName });
        const subpath = ['ROUTES.CATEGORY', categoryFromUrl];
        const canonical = `${absoluteBaseHref}/${t(subpath[0])}/${subpath[1]}`;
        const LDJsonData = LDJsonTemplateService.buildGameListTemplate(games, absoluteBaseHref, lang);
        const seoHreflangLinks = generateSeoHreflangLinksList(
            absoluteBaseHref,
            this.props.arenaLang,
            subpath);

        return (
            <ErrorBoundary>
                {(
                    <ConnectedHelmet
                        title={metaTitle}
                        meta={this.getMeta(canonical)}
                        link={[
                            { rel: 'canonical', href: canonical },
                            ...seoHreflangLinks]}
                    >
                        <script async type="application/ld+json">
                            {JSON.stringify(LDJsonData)}
                        </script>
                    </ConnectedHelmet>
                )}

                <CategoryTemplate
                    categoryKey={this.categoryKey}
                    category={this.category}
                    caption={frenchTextFix(caption)}
                    games={games}
                    isAdsEnabled={isAdsEnabled}
                    themeName={themeName}
                    client={client}
                    disablePoweredBy={disablePoweredBy}
                    eventCustomStyles={this.props.eventCustomStyles}
                    eventsCustomClass={this.props.eventsCustomClass}
                />
            </ErrorBoundary>
        );
    }

    private getCurrentCategory() {
        const { allCategoriesKeys, categoryFromUrl, t, i18n } = this.props;
        const categoryDeserialized = UrlService.deserializeCategoryFromUrl(categoryFromUrl);

        this.categoryKey =
            allCategoriesKeys.find((key) => {
                // checking category either deserialized or as is from URL.
                return (
                    t(key.toUpperCase()).toLowerCase() === categoryDeserialized.toLowerCase() ||
                    t(key.toUpperCase()).toLowerCase() === categoryFromUrl.toLowerCase()
                );
            }) || '';

        // we assume that if there isn't a translation for the "categoryKey", category is a custom
        this.isCategoryCustom = !i18n.exists(this.categoryKey.toUpperCase());
        this.category = this.isCategoryCustom ? this.categoryKey : t(this.categoryKey.toUpperCase());
    }
}

const CategoryState = connect((state: AppState, props: RouteComponentProps<any>) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
    allGames: gamesByLangSelector(state),
    categoryFromUrl: props.match.params.category,
    clientName: state.config.theme.name,
    client: state.config.theme.client,
    disablePoweredBy: state.config.theme.disablePoweredBy,
    isAdsEnabled: state.config.theme.shouldShowDisplayAd(),
    location: state.router.location,
    lang: state.currentLang,
    allCategoriesKeys: state.config.categories.allCategories,
    themeName: state.config.theme.theming.name,
    arenaLang: state.config.theme.locale,
    eventCategoryConfig: state.config.eventCategoryConfig,
    eventCustomStyles: generateEventCustomStyles(state),
    eventsCustomClass: generateEventCustomClass(state, 'categorypage'),
    sharingImage: state.config.theme.sharingImage,
}))(CategoryPageBase);

export const CategoryPage = withTranslation()(CategoryState as any);
