import Cookies from 'js-cookie';
import { QuantcastService } from './QuantcastService';

class CookieStorageProvider {

    public set(key: string, value: any, options?: any) {
        if (QuantcastService.isEnabled && QuantcastService.blockedByGdprConsent()) {
            console.log('CookieStorage.set blocked = ' + key);
            return;
        }

        Cookies.set(key, value, options);
    }

    public get(key?: string) {
        return Cookies.get(key);
    }

    public remove(key: string) {
        Cookies.remove(key);
    }
}

export const CookieStorageService = new CookieStorageProvider();
