import { useABTest } from '../../services/ABTests/ABTestReact';
import { AbTestsConsole } from '../../atoms/AbTestExperiments/AbTestsConsole';
import styles from './AnnounceBanner.css';
import { isServer } from '../../../utils';
import {CookieStorageService} from "../../services/CookieStorage";

export const XfinityAnnounceABTest = () => {
    if (isServer) { return ; }

    const variation = useABTest('abtest_Xfinity', 'Arena');

    AbTestsConsole.abTestShareInfo('abtest_Xfinity', variation);

    const key = 'xfinityMsg';
    const arkAbTest = CookieStorageService.get('arkabtests');
    const isABTestExist = (arkAbTest && arkAbTest.indexOf('abtest_Xfinity') !== -1);

    if (!variation || !isABTestExist) {
        localStorage.removeItem(key);
        return null;
    }

    if (variation === 'Ark') {
        localStorage.setItem(key, `As of July 3rd, 2023, Xfinity's games website will close. You can still enjoy all of your favorite games at <a class='` + styles.BannerUrl + `' href='//arkadium.com?arkpromo=xfark'>website</a>`);
    } else {
        localStorage.setItem(key, `As of July 3rd, 2023, Xfinity's games website will close. You can still enjoy all of your favorite games at <a class='` + styles.BannerUrl + `' href='//games.arkadium.com?arkpromo=xfgames'>website</a>`);
    }

    return null;
};
